.settings-container {
  max-width: 1000px;
  margin-left: 20px;
  padding: 20px;
  color: white;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: white;
}

.settings-layout {
  display: flex;
}

.settings-tabs {
  display: flex;
  flex-direction: column;
  width: 200px;
  border-right: 1px solid #444;
  margin-right: 20px;
}

.tab-button {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s;
  color: white;
}

.tab-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.tab-button.active {
  background-color: rgba(255, 255, 255, 0.2);
  font-weight: bold;
  border-right: 2px solid #4CAF50;
  color: #4CAF50;
}

.settings-content {
  flex-grow: 1;
  padding: 0 20px;
}

.connections-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.connect-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.connect-button.notion {
  background-color: #000000;
  color: #ffffff;
}

.connect-button.notion:hover {
  background-color: #333333;
}

.connect-button.github {
  background-color: #24292e;
  color: #ffffff;
}

.connect-button.github:hover {
  background-color: #444d56;
}

.connect-button.disconnect {
  background-color: #dc3545;
}

.connect-button.disconnect:hover {
  background-color: #c82333;
}

/* Remove unused styles */
.connection-toggle,
.toggle-switch {
  display: none;
}

.api-access-content {
  margin-top: 20px;
}

.api-access-content h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.api-key-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.api-key-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
  border-radius: 4px 0 0 4px;
}

.toggle-visibility-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0 4px 4px 0;
}

.toggle-visibility-btn:hover {
  background-color: #45a049;
}

/* ... rest of the styles ... */